<template>
  <div class="page-content about">
    <section data-aos="fade-up" id="intro" class="section-r">
      <img data-aos="fade-in" data-aos-delay="850" class="section-bg" src="@/assets/img/about/Yi.svg" alt="yi" />

      <b-row>
        <b-col cols="0" md="4"></b-col>
        <b-col cols="12" md="8" class="text-content">
          <h1>{{ $t("aboutUs.section1.title") }}</h1>
          <p class="page-excerpt">
            <span>{{ $t("aboutUs.section1.subTitle") }}</span>
          </p>

          <p>
            <span>{{ $t("aboutUs.section1.content") }}</span>
          </p>
        </b-col>
      </b-row>
    </section>

    <section id="client" class="section-l">
      <!-- <img class="section-bg" src="@/assets/img/about/about_02.jpg" alt /> -->

      <b-row data-aos="fade-up" data-aos-delay="550" style="position: relative">
        <b-col cols="12" md="8" class="text-content text-right">
          <h1>{{ $t("aboutUs.section2.title") }}</h1>
          <p>
            <span class="page-excerpt">{{ $t("aboutUs.section2.subTitle") }}</span>
          </p>
          <p>
            <span>{{ $t("aboutUs.section2.content") }}</span>
          </p>
        </b-col>
        <b-col cols="0" md="4"></b-col>
      </b-row>
      <b-row data-aos="fade-up" class="content clients-container">
        <b-col cols="6" md="4" class="icon-warpper" v-for="(client, i) in clientLogo" :key="i">
          <img :src="require(`@/assets/img/about/client-icons/${client}.svg`)" alt="client-icon" />
        </b-col>
      </b-row>
    </section>

    <section data-aos="fade-up" data-aos-delay="550" class="section-r">
      <b-row>
        <b-col cols="0" md="4"></b-col>
        <b-col cols="12" md="8">
          <h1>{{ $t("aboutUs.section3.title") }}</h1>
          <p>
            <span>
              {{ $t("aboutUs.section3.subTitle") }}
              <!-- <a
                class="btn mt-3"
                href="javascript:;"
              >{{ $t("aboutUs.section3.button") }}</a> -->
            </span>
          </p>

          <p>
            <span>{{ $t("aboutUs.section3.content") }}</span>
          </p>
        </b-col>
      </b-row>

      <div class="langlist">
        <b-row>
          <b-col cols="12" md="auto" v-for="(col, i) in langObj" :key="i">
            <ul>
              <li v-for="(lang, j) in col" :key="j">{{ lang }}</li>
            </ul>
          </b-col>
        </b-row>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'About Us'
  },
  data() {
    return {
      langArr: ['ENGLISH', 'CANTONESE', 'FRENCH', 'MANDARIN', 'KOREAN', 'PUNJABI'],
      clientLogo: [
        'onni',
        'polygon',
        'mosaic',
        'rennie',
        'bcaa',
        'cathaybank',
        'cbre',
        'colliers',
        'dava',
        'sprottshaw',
        'brands',
        'directionlegal'
      ]
    }
  },
  computed: {
    langObj() {
      const res = {}
      let count = this.$t('aboutUs.langArr').length / 2

      if (this.langArr.length % 2) {
        count = this.langArr.length / 2 - 1
      }

      this.$t('aboutUs.langArr').forEach((el, i) => {
        if (i < count) {
          if (!res.col1) {
            res.col1 = []
          }
          res.col1.push(el)
        } else {
          if (!res.col2) {
            res.col2 = []
          }
          res.col2.push(el)
        }
      })

      return res
    }
  }
}
</script>

<style lang="scss">
.about {
  padding-top: 80px !important;

  section {
    position: relative;
    // overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 1rem;
    margin-bottom: 80px;
  }

  .clients-container {
    // margin: 0 -22px;
    padding: 0;

    .icon-warpper {
      padding: 0;

      img {
        width: 100%;
      }
    }
  }

  // section#client{

  //   min-height: 600px;
  // }
  .section-bg {
    position: absolute;
    // width: 600px;
    height: 120%;
    max-width: 100%;
    // max-width: 800px;
    object-fit: contain;
  }

  .section-r {
    .section-bg {
      left: 15%;
    }
  }

  p {
    span {
      display: block;
      max-width: 600px;
    }
  }
}

.langlist {
  margin-top: 5rem;

  .row {
    justify-content: center;
  }

  // .col-sm-12 {
  //   max-width: 300px;
  // }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto;
    padding: 0;

    li {
      letter-spacing: 0.5rem;
      font-weight: 700;
      text-transform: uppercase;
      color: $bronze;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 200px;
      padding-left: 0.5rem;

      &::after {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        display: block;
        background: #fff;
        margin: 2rem;
        margin-left: 1.5rem;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .about {
    padding-top: 150px !important;

    section {
      margin-bottom: 150px;
    }
  }

  .page-content {
    section {
      padding: 0;
    }
  }

  .langlist {
    ul {
      padding: inherit;
    }

    .col-12:nth-child(1) ul {
      li:nth-child(3)::after {
        display: none
      }
    }

    .col-12 {
      &:nth-child(2) {
        margin-top: 115px;
      }
    }
  }
}
</style>
